import { render, staticRenderFns } from "./PrimaryToolbar.vue?vue&type=template&id=427ce2d8&scoped=true"
import script from "./PrimaryToolbar.vue?vue&type=script&lang=js"
export * from "./PrimaryToolbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427ce2d8",
  null
  
)

export default component.exports