import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Authentication
import { authentication } from "./modules/authentication/authentication";

// Main Stores
import {
  registrants,
  registrant_vaccines,
} from "./modules/citizens/registrants";
import { card } from "./modules/card/card";
import { services } from "./modules/hospital-services/services";
import { consultation_form } from "./modules/consultation-form/consultation-form";
import {
  dialysis_sessions,
  dialysis_sessions_packages,
} from "./modules/dialysis-sessions/dialysis_sessions";
import { files } from "./modules/files/files";
import { dialysis_calendar } from "./modules/dialysis-calendar/dialysis_calendar";
import { dashboard, funders_actions } from "./modules/dashboard/dashboard";
import { accounts } from "./modules/accounts/accounts";
import { dialysis_general } from "./modules/dialysis-general/dialysis_general";
import { laboratories } from "./modules/laboratories/laboratories";

// Public
import { publicData } from "./modules/public-data/public-data";

// Enums
import { categories } from "./modules/enums/categories";
import { philippines } from "./modules/enums/philippines";
import { services_choices } from "./modules/enums/services-choices";
import { consultations } from "./modules/consultations/consultations";
import { consultation_enum } from "./modules/enums/consultation-enum";
import { identification_cards } from "./modules/enums/identification-cards";
import { professions } from "./modules/enums/professions";
import { management } from "./modules/enums/management";
import { file_types, file_types_actions } from "./modules/enums/file-types";
import { dialysis, dialysis_items_actions } from "./modules/enums/dialysis";
import { hospitals } from "./modules/enums/hospitals";
import { features } from "./modules/enums/features";
import { generalServices } from "./modules/enums/general-services";
import { specialties } from "./modules/enums/specialties";
import { laboratory } from "./modules/enums/laboratory";
import { diagnostics } from "./modules/enums/diagnostics";
import { diagnosticsList } from "./modules/diagnostics-list/diagnostics-list";

// Global
import { alerts } from "./modules/alerts";
import { loading } from "./modules/loading";
import { message } from "./modules/message/message";
// import { funding } from "./modules/Admin/funding";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // Authentication
    authentication,

    // Global
    loading,
    alerts,
    message,

    // Citizens
    registrants,
    card,
    services,
    consultations,
    registrant_vaccines,

    //Admin Exclusive Stores
    dialysis_items_actions,
    funders_actions,

    // funding,
    file_types_actions,

    //Encoder
    consultation_form,
    dialysis_sessions,
    dialysis_sessions_packages,
    dialysis_calendar,
    diagnosticsList,
    laboratories,

    // Public
    publicData,

    //Enums
    identification_cards,
    professions,
    philippines,
    categories,
    consultation_enum,
    services_choices,
    file_types,
    dialysis,
    management,
    features,
    generalServices,
    hospitals,
    specialties,
    laboratory,
    diagnostics,

    //General
    files,
    dashboard,
    dialysis_general,

    //Management
    accounts,
  },
  plugins: [
    createPersistedState({
      paths: ["authentication.currentUser"],
    }),
  ],
});
