<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card flat>
      <PrimaryToolbar :title="title" @onClose="dialog = false" />

      <v-form class="pa-4" ref="form" @submit.prevent="submitForm">
        <slot />

        <div class="text-right mt-4" v-if="!withoutSubmit">
          <v-btn type="submit" color="primary" :loading="loading" depressed
            >Submit</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryToolbar from "../toolbars/PrimaryToolbar.vue";
export default {
  name: "DialogTemplate",
  components: {
    PrimaryToolbar,
  },
  props: {
    title: String,
    opened: Boolean,
    onClose: Function,
    loading: Boolean,
    withoutSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    submitForm: function () {
      if (this.$refs.form.validate()) {
        this.$emit("onSubmit");
      }
    },
    resetValidation: function () {
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },
  watch: {
    opened: {
      handler: function (v) {
        this.dialog = v;

        this.$nextTick(() => {
          if (this.$refs.form) {
            this.resetValidation();
          }
        });
      },
    },
    dialog: {
      handler: function (v) {
        if (!v) {
          this.onClose();
        }
      },
    },
  },
};
</script>

<style scoped></style>
