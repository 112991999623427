import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const message = {
  namespaced: true,
  state: () => ({
    message: "",
    opened: false,
  }),
  getters: {},
  mutations: {
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_OPENED(state, opened) {
      state.opened = opened;
    },
  },
};
