import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const diagnosticsList = {
  namespaced: true,
  state: () => ({
    diagnostics: null,
    diagnostic: null,
  }),
  getters: {},
  mutations: {
    SET_DIAGNOSTICS: (state, data) => (state.diagnostics = data),
    SET_DIAGNOSTIC: (state, data) => (state.diagnostic = data),
  },
  actions: {
    fetchDiagnostics: function ({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `encoder/diagnostics`;
      const query = functions.query(url, queryParams);
      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_DIAGNOSTICS", response.data);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
        .catch((error) => {
          console.error("Error fetching diagnostics: ", error);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
        
    },
    showDiagnostic: function ({ commit }, id) {
      const url = `encoder/diagnostics/${id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DIAGNOSTIC", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching show diagnostic: ", error);
        });
    },
    toggleDiagnosticStatus: function (
      { dispatch },
      { diagnostic_id, hospital_service_id, status }
    ) {
      const url = `hospital-services/${hospital_service_id}/toggle-status`;
      return this.$axios
        .patch(url, { status })
        .then((response) => {
          dispatch("showDiagnostic", diagnostic_id);
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error toggling diagnostic status: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        });
    },
  },
};
