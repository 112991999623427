import router from "@/router";
import store from "@/store";

export const auth = {
  user: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user;
  },
  token: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user && user.token ? user.token : null;
  },
  role: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user && user.user.role ? user.user.role : null;
  },
  logout: function () {
    store.commit("message/SET_OPENED", true);
    store.commit(
      "message/SET_MESSAGE",
      "Your session has expired. Redirected to login page."
    );
    store.commit("authentication/SET_CURRENT_USER", null);
    router.push("login");
  },
};
