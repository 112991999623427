import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const dashboard = {
  namespaced: true,
  state: () => ({
    dashData: null,
    dashRoot: null,
  }),
  getters: {},
  mutations: {
    SET_DASHBOARD_DATA(state, data) {
      state.dashData = data;
    },
    SET_DASHBOARD_ROOT_DATA(state, rootData) {
      state.dashRoot = rootData;
    },
  },
  actions: {
    fetchGeneralDashboardData({ commit }) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `dashboard`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DASHBOARD_DATA", response.data.data);
        })
        .catch((error) => {
          console.error("Error Fetching Dashboard Data: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", "Error fetching dashboard data: " + error)
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
    fetchRootDashboardData({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `dashboard/root`;
      const query = functions.query(url, queryParams);
      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_DASHBOARD_ROOT_DATA", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching root data: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", "Error fetching root data: " + error)
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
  },
};

export const funders_actions = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    createFunder(_, data) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/funders`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          this.$store.dispatch("dashboard/fetchRootDashboardData");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error Adding Funder: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    updateFunder(_, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/funders/${id}`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          this.$store.dispatch("dashboard/fetchRootDashboardData");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error Updating Funder: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    deleteFunder(_, id) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/funders/${id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          this.$store.dispatch("dashboard/fetchRootDashboardData");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error Deleting Funder: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
