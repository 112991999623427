// store/modules/registrants.js
import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const registrants = {
  namespaced: true,
  state: () => ({
    registrants: null,
    registrant: null,
  }),
  getters: {},
  mutations: {
    SET_REGISTRANTS(state, registrants) {
      state.registrants = registrants;
    },
    SET_REGISTRANT(state, registrant) {
      state.registrant = registrant;
    },
  },
  actions: {
    fetchRegistrants({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `citizens`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const registrants = response.data;
          commit("SET_REGISTRANTS", registrants);
        })
        .catch((error) => {
          console.error("Error fetching registrants:", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
    addRegistrant({ dispatch }, data) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/create`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);

          //Call Again Fetch Registrants
          dispatch("fetchRegistrants");
        })
        .catch((error) => {
          console.error("Error adding citizen:", error);

          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    fetchRegistrantId({ commit }, id) {
      const url = `citizens/${id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          const registrant = response.data;
          commit("SET_REGISTRANT", registrant);
        })
        .catch((error) => {
          console.error("Error fetching registrant:", error);
        });
    },
    updateRegistrant({ dispatch }, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);

          //Call Again Fetch Registrants
          dispatch("fetchRegistrants");
        })
        .catch((error) => {
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error updating request to registrant vuex:", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    updateRegistrantFiles({ dispatch }, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/files`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          //Store dispatch so that images will be updated
          this.$store.dispatch("card/fetchImage", id);
          this.$store.dispatch("card/fetchSignature", id);
          this.$store.dispatch("card/fetchBiometrics", id);

          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);

          dispatch("fetchRegistrantId", id);
        })
        .catch((error) => {
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error updating registrant files:", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    claimCard({ dispatch }, id) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/card`;
      return this.$axios
        .patch(url)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchRegistrantId", id);
        })
        .catch((error) => {
          console.error("Error requesting claim:", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    deleteRegistrant({ dispatch }, id) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `citizens/${id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchRegistrants");
        })
        .catch((error) => {
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error deleting registrant:", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },

    deviceRequestErrorAlert(_, errorMessage) {
      this.$store.commit("alerts/SET_SHOW_ERROR", errorMessage);
    },

    // RESET METHODS
    resetCitizen: function ({ commit }) {
      commit("SET_REGISTRANT", null);
    },
  },
};

export const registrant_vaccines = {
  namespaced: true,
  state: () => ({
    vaccinationDetails: [],
    boosterDetails: [],
  }),
  getters: {},
  mutations: {
    SET_VACCINATION_INFORMATION(state, vaccineInformation) {
      state.vaccinationDetails = vaccineInformation;
    },
    SET_BOOSTER_INFORMATION(state, boosterInformation) {
      state.boosterDetails = boosterInformation;
    },
  },
  actions: {
    fetchVaccineInformation({ commit }, id) {
      commit("SET_VACCINATION_INFORMATION", []);
      const url = `citizens/${id}/vaccines`;
      return this.$axios
        .get(url)
        .then((response) => {
          const vaccineInformation = response.data;
          commit(
            "SET_VACCINATION_INFORMATION",
            vaccineInformation.vaccinationStats
          );
        })
        .catch((error) => {
          console.error("Error fetching vaccine information:", error);
        });
    },
    updateVaccineInformation({ dispatch }, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/vaccines/add-or-update`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchVaccineInformation", id);
          this.$store.dispatch("registrants/fetchRegistrantId", id);
        })
        .catch((error) => {
          console.error("Error updating vaccination information:", error);
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    fetchBoosterInformation({ commit }, id) {
      commit("SET_BOOSTER_INFORMATION", []);
      const url = `citizens/${id}/boosters`;
      return this.$axios
        .get(url)
        .then((response) => {
          const boosterInformation = response.data;
          commit("SET_BOOSTER_INFORMATION", boosterInformation.boosterStats);
        })
        .catch((error) => {
          console.error("Error fetching booster information:", error);
        });
    },
    updateBoosterInformation({ dispatch }, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/boosters/add-or-update`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchBoosterInformation", id);
        })
        .catch((error) => {
          console.error("Error updating booster information:", error);
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    deleteVaccineById({ dispatch }, { id, vaccine_id }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `citizens/${id}/vaccines/${vaccine_id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchVaccineInformation", id);
        })
        .catch((error) => {
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error Deleting Vaccine: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
    deleteBoosterById({ dispatch }, { id, vaccine_id }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `citizens/${id}/boosters/${vaccine_id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          //Commit to the other module for alert
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchBoosterInformation", id);
          this.$store.dispatch("loading/detriggerTableLoader", "delete");
        })
        .catch((error) => {
          //Commit to the other module for alert
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error Deleting Booster: ", error);
          this.$store.dispatch("loading/detriggerTableLoader", "delete");
        });
    },
  },
};
