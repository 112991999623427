import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const alerts = {
  namespaced: true,
  state: () => ({
    alert: null,
    alertTimeout: null,
  }),
  getters: {},
  mutations: {
    SET_SHOW_ALERT(state, message) {
      state.alert = {
        type: "success",
        message: message,
      };

      if (state.alertTimeout) {
        clearTimeout(state.alertTimeout);
      }

      state.alertTimeout = setTimeout(() => {
        state.alert = null;
      }, 3000);
    },
    SET_SHOW_ERROR(state, message) {
      state.alert = {
        type: "error",
        message: message,
      };

      if (state.alertTimeout) {
        clearTimeout(state.alertTimeout);
      }

      state.alertTimeout = setTimeout(() => {
        state.alert = null;
      }, 3000);
    },
    RESET_ALERT: (state) => {
      state.alert = null;
      if (state.alertTimeout) {
        clearTimeout(state.alertTimeout);
      }
    },
  },
  actions: {
    resetLoading: function ({ commit }) {
      commit("RESET_ALERT");
    },
  },
};
