import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const consultations = {
  namespaced: true,
  state: () => ({
    consultations: null,
    consultation: null,
  }),
  getters: {
    getConsultation: (state) => {
      if (state.consultation) {
        const consultation = state.consultation.consultation;
        return consultation;
      }
    },
    getPreviousConsultations: (state) =>
      state.consultation?.consultation.previous_consultation_forms,
  },
  mutations: {
    SET_CONSULTATIONS(state, consultations) {
      state.consultations = consultations;
    },
    SET_CONSULTATION(state, consultation) {
      state.consultation = consultation;
    },
  },
  actions: {
    // INDEX METHODS
    fetchConsultationsForDoctor: function ({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `doctors/consultations`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_CONSULTATIONS", response.data);
        })
        .catch((error) => {
          console.error("Error Fetching Consultations For Doctor: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
    fetchConsultationsForAdmin: function ({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `admin/consultations`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_CONSULTATIONS", response.data);
        })
        .catch((error) => {
          console.error("Error Fetching Consultations For Admin: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },

    // SHOW METHODS
    showConsultationForDoctor: function ({ commit }, consultation_id) {
      const url = `doctors/consultations/${consultation_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_CONSULTATION", response.data);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation For Doctor: ", error);
        });
    },
    showConsultationForAdmin: function ({ commit }, consultation_id) {
      const url = `admin/consultations/${consultation_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_CONSULTATION", response.data);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation For Admin: ", error);
        });
    },

    // PATCH METHODS
    toggleServiceProgress(
      { dispatch },
      { hospital_service_id, consultation_id, status }
    ) {
      const url = `hospital-services/${hospital_service_id}/toggle-status`;
      return this.$axios
        .patch(url, status)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchConsultationById", consultation_id);
        })
        .catch((error) => {
          console.error("Error Patching Status: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        });
    },
  },
};
