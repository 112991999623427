import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const laboratory = {
  namespaced: true,
  state: () => ({
    laboratory_types: null,
    laboratory_type: null,
    tableLoader: false,
  }),
  getters: {},
  mutations: {
    SET_SPECIALTY_TYPES: (state, data) => (state.laboratory_types = data),
    SET_SPECIALTY_TYPE: (state, data) => (state.laboratory_type = data),
    SET_TABLE_LOADER: (state, data) => (state.tableLoader = data),
  },
  actions: {
    fetchLaboratoryTypes: function ({ commit }) {
      commit("SET_TABLE_LOADER", true);
      const url = `enums/hospital-services/laboratory-types`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTY_TYPES", response.data.laboratoryType);
          commit("SET_TABLE_LOADER", false);
        })
        .catch((error) => {
          console.error("Error fetching laboratory specialty: ", error);
          commit("SET_TABLE_LOADER", false);
        });
    },
    fetchLaboratoryType: function ({ commit }, laboratoryTypeID) {
      const url = `enums/hospital-services/laboratory-types/${laboratoryTypeID}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTY_TYPE", response.data.laboratoryType);
        })
        .catch((error) => {
          console.error("Error fetching laboratory type: ", error);
        });
    },
    createLaboratoryType: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/laboratory-types`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchLaboratoryTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error creating laboratory specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    updateLaboratoryType: function (
      { dispatch },
      { laboratoryTypeID, payload }
    ) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/laboratory-types/${laboratoryTypeID}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchLaboratoryTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error updating laboratory specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    deleteLaboratoryType: function ({ dispatch }, laboratoryTypeID) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/laboratory-types/${laboratoryTypeID}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchLaboratoryTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error deleting laboratory specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
