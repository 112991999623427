import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const generalServices = {
  namespaced: true,
  state: () => ({
    generalServices: [],
    generalService: null,
    tableLoader: false,
  }),
  getters: {},
  mutations: {
    SET_GENERAL_SERVICES: (state, data) => {
      state.generalServices = data;
    },
    SET_GENERAL_SERVICE: (state, data) => {
      state.generalService = data;
    },
    SET_TABLE_LOADER: (state, data) => (state.tableLoader = data),
  },
  actions: {
    fetchGeneralServices: function ({ commit }) {
      commit("SET_TABLE_LOADER", true);
      const url = `general-services`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_GENERAL_SERVICES", response.data.data);
          commit("SET_TABLE_LOADER", false);
        })
        .catch((error) => {
          console.error("Error fetching general services: " + error);
          commit("SET_TABLE_LOADER", false);
        });
    },
    showGeneralService: function ({ commit }, generalServiceId) {
      const url = `general-services/${generalServiceId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_GENERAL_SERVICE", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching general service: " + error);
        });
    },
    createGeneralService: function (_, payload) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `general-services`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          return response;
        })
        .catch((error) => {
          this.$storetore.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          console.error("Error creating general service: " + error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    updateGeneralService: function (_, { payload, generalServiceId }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `general-services/${generalServiceId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          return response;
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          console.error("Error updating general service: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
    },
    deleteGeneralService: function (_, generalServiceId) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `general-services/${generalServiceId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          return response;
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          console.error("Error deleting general service: " + error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
    resetGeneralService: function ({ commit }) {
      commit("SET_GENERAL_SERVICE", null);
    },
  },
};
