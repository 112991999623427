<template>
  <DialogTemplate
    title="IMPORTANT MESSAGE"
    :opened="opened"
    :onClose="onClose"
    withoutSubmit
  >
    <v-card-text class="pa-0 pa-sm-2 text-body-2 text-sm-body-1">
      {{ message }}
    </v-card-text>
  </DialogTemplate>
</template>

<script>
import DialogTemplate from "../DialogTemplate.vue";
export default {
  name: "MessageDialog",
  components: {
    DialogTemplate,
  },
  props: {
    opened: Boolean,
    onClose: Function,
    message: String,
  },
  data: () => ({}),
};
</script>

<style scoped></style>
