<template>
  <v-toolbar flat class="white--text" color="primary">
    <v-btn icon color="white" @click="$emit('onClose')">
      <v-icon>mdi-close</v-icon></v-btn
    >
    <div class="font-weight-bold">{{ title }}</div>
  </v-toolbar>
</template>

<script>
export default {
  name: "PrimaryToolbar",
  props: {
    title: String,
  },
};
</script>

<style scoped></style>
