<template>
  <v-app
    :class="{
      'bg-image': $route.name === 'login' && !$vuetify.breakpoint.xs,
    }"
  >
    <NavComponents v-if="!noNavBarRoutes.includes($route.name)" />
    <!-- Container -->
    <v-container
      :style="containerWidth"
      class="container-height"
      v-if="!isFileView"
    >
      <v-main class="flex-1">
        <AlertComponent
          v-if="!noAlertRoutes.includes($route.name)"
          class="alert-fixed"
        />
        <router-view />
      </v-main>
    </v-container>

    <!-- Non-Container -->
    <v-main class="flex-1" v-else>
      <router-view />
    </v-main>

    <MessageDialog :opened="opened" :message="message" :onClose="onClose" />
    <FooterComponent />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NavComponents from "./layouts/NavComponents.vue";
import FooterComponent from "./layouts/Footer/FooterComponent.vue";
import ContainerBreakpoint from "./mixins/ContainerBreakpoint";
import AlertComponent from "./components/AlertComponent.vue";
import MessageDialog from "./components/dialogs/message/MessageDialog.vue";
export default {
  name: "App",
  mixins: [ContainerBreakpoint],
  data: () => ({
    noNavBarRoutes: [
      "login",
      "reroute",
      "public-file-view",
      "Minalin Dialysis Center",
    ],
    noContainerRoutes: [
      "login",
      "dialysis-files-view",
      "consultation-files-view",
      "public-file-view",
      "reroute",
    ],
    noAlertRoutes: [],
  }),
  components: {
    NavComponents,
    FooterComponent,
    AlertComponent,
    MessageDialog,
  },
  computed: {
    ...mapState("message", ["message", "opened"]),
    containerWidth() {
      if (!this.noContainerRoutes.includes(this.$route.name)) {
        return `max-width: ${this.breakpointVal};`;
      } else if (this.$route.name === "reroute") {
        return `max-width: 1280px`;
      } else {
        return `max-width: 100%; padding: 0;`;
      }
    },
    isFileView: function () {
      return this.$route.name && this.$route.name.includes("files-view");
    },
  },
  methods: {
    ...mapActions("message", ["toggleDialog", "messageContent"]),
    onClose: function () {
      this.toggleDialog(false);
      this.messageContent("");
    },
  },
  watch: {
    opened: {
      handler: function (v) {
        console.log(v);
      },
    },
  },
};
</script>

<style scoped>
.bg-image {
  background: url(./assets/login/loginBg.svg) no-repeat center center/cover;
}

.container-height {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.alert-fixed {
  position: sticky;
  top: 60px;
  z-index: 5;
  width: 100%;
}
</style>
